import { Button, TextField, CssBaseline, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import React, {useState} from 'react'
import QRCode from "react-qr-code"

const useStyles = makeStyles((theme) => ({
  container: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      //display: 'flex',
      alignItems: 'center',

  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
      /* marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', */
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
  },
  form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
  },
  submit: {
      margin: theme.spacing(3, 0, 2),
  },
  formControl: {
      width: '100%',
  },
  selectEmpty: {
      marginTop: theme.spacing(2),
  },
}));


const onImageCownload = () => {
  const svg = document.getElementById("QRCode");
  const svgData = new XMLSerializer().serializeToString(svg);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const img = new Image();
  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const pngFile = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.download = "QRCode";
    downloadLink.href = `${pngFile}`;
    downloadLink.click();
  };
  img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  //img.src = `data:image/svg+xml;base64,${Buffer.from(svgData).toString('base64')}`;
};

const Qr = () => {
  const classes = useStyles();
  const [texto, setTexto] = useState("https://cymaniatico.com")
  return (
    <Container component="main" maxWidth="xs"  className={classes.container}>
      <CssBaseline />
      <div className={classes.paper}>
        <Grid item xs={12} sm={12}>
          <div className={classes.paper}>
            <TextField
              name="texto"
              variant="outlined"
              defaultValue={texto}
              required
              id="texto"
              label="Ingrese el enlace"
              onChange={e => setTexto(e.target.value)} />
            <QRCode id="QRCode" value={texto} className={classes.submit}/>
            <Button color="primary" variant='contained' onClick={onImageCownload}>Descargar QR</Button>
          </div>  
        </Grid>
      </div>
    </Container>
  )
}

export default Qr
